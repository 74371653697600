footer {
    background-color: #191919;
    padding: 20px 0;
    color: #f5f5f5;
    text-align: center;

    @media screen and (max-width:390px) {
        padding: 15px 0;
    }

    .footer-socials {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;


        ul {
            display: flex;
            list-style: none;
            
            li {
                margin: 0 1rem;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #f5f5f5;
                    font-size: 1.5rem;

                    &:hover {
                        color: #d28d29;
                    }
                }
            }
        }
    }

    p {
        margin-bottom: 20px;

        @media screen and (max-width: 768px){
            margin-bottom: 10px;
        }

        @media screen and (max-width:390px) {
            font-size: 14px;
        }
    }
 
    .back-to-top {
        a {
            color: #f5f5f5;
            cursor: pointer;

            &:hover {
                color: #d28d29;
            }
        }
    }
}
