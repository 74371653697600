#portfolio {
    padding: 40px;
    background-color: #28282b;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    h2 {
        font-size: 30px;
        color: #f5f5f5;
        text-align: center;
        position: relative;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            font-size: 25px;
            margin-top: 0;
            margin-bottom: 20px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            border-bottom: 2px solid #d28d29;
            width: 45px;
            margin: auto;
        }
    }
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px;

   
}  
#portfolio {
    position: relative;
    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 540px;
        height: 80vh;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 30px;
        border: 1px solid #191919;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        overflow-y: auto;

        @media screen and (max-width: 1024px){
            width: 70%;
        }

        @media screen and (max-width:430px){
            width: 90%;
            top: 55%;
            overflow-y:scroll ;
            padding: 20px;
        }

        .closeButton {
            position: absolute;
            top: 0;
            right: 3%;
            font-weight: 500;
        }

        button {
            background-color: transparent;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
        }

        h3 {
            font-size: 20px;
            text-align: center;
            padding-bottom: 10px;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            text-align: justify;

            @media screen and (max-width:390px){
                font-size: 0.9rem;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: center;

            li {
                padding: 0.2rem 0.5rem;
                background-color: #d28d29;
                border-radius: 5px;
                font-weight: 500;

                @media screen and (max-width: 390px) {
                    font-size: 0.9rem;
                }
            }
        }

        .carousel {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding-top: 1rem;

            @media screen and (max-width:390px){
                gap: 0;
            }

            button {
                background-color: transparent; 
            }

            img {
                width: 90%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    &.modal-open {
        &:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 999;
        }
    }

   
  
} 
.scroll-lock {
        overflow-y: hidden;
    }