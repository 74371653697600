.project {
    background-color:  #191919;
    padding: 20px;
    width: 350px;
    height: 500px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    text-align: center;
    overflow: hidden;
    margin: 20px;
    margin-top: 40px;
    position: relative;
    
    @media screen and (max-width:829px){
        width: 320px;
        margin: 15px;
    }

    @media screen and (max-width:710px){
        width: 360px;
        margin: 10px;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.03);

        &::after {
            content: "Voir plus";
            position: absolute;
            bottom: 2.5%;
            right: 2.5%;
            color: #f5f5f5;
            font-size: 18px;
            font-weight: 600;
            background-color: rgba(25, 25, 25, 0.7);
            border-radius: 8px;
            width: 95%;
            height: 95%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    img {
        width: 100%;
        height: 90%;
        border-radius: 8px;
        margin-bottom: 15px;
    }

    h3 {
        color: #f5f5f5;
    }
}