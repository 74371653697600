
.about {
    text-align: center;
    padding: 50px 0;
    background: #191919;
    color: #f5f5f5;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }

    h2 {
        margin-bottom: 85px;
        position: relative;
        display: inline-block;
        font-size: 30px;

        @media screen and (max-width:768px) {
            font-size: 25px;
            margin-bottom: 40px;
        }
        
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            border-bottom: 2px solid #d28d29;
            width: 45px;
            margin: auto;
        }
    }
  

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;

        @media screen and (max-width: 1050px) {
            flex-direction: column;
            gap: 20px;
        }

        &__image {
           flex: 1;

            img {
                border-radius: 50%;
                width: 65%;
                opacity: 0.8;
            }
        }

        &__content {
            flex: 1;
            line-height: 23px;
            color: #fff;
            padding: 15px 10px;
            border-radius: 5px;
            background-color: #28282b;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }

            h3 {
                padding-bottom: 10px;
                color: #d28d29;
            }
            
            p:first-of-type {
                padding-bottom: 5px;
                font-size: 17px;
            }

            p:last-of-type {
                text-align: justify;
            }
            
        }
    }

    &__button {
        margin-top: 1.5rem;

        a {
            display: inline-block;
            color:#fff;
            padding: .75rem 1.25rem;
            border-radius: 5rem;
            text-transform: uppercase;
            text-decoration: none;
            transition: all .3s;
            position: relative;
            overflow: hidden;
            z-index: 1;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -2;
                border: 5rem;
                background-color: #d28d29;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: darken(#d28d29, 15%);
                border-radius: 5rem;
                transition: all .3s;
                z-index: -1;
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
        
    }
}