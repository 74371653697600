#contact {
    background-color: #28282b;
    text-align: center;
    padding: 60px 0;

    @media screen and (max-width: 768px){
        padding: 30px 0;
    }

    h2 {
        font-size: 30px;
        color: #f5f5f5;
        margin-bottom: 30px;
        text-align: center;
        position: relative;

        @media screen and (max-width: 768px){
            font-size: 25px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            border-bottom: 2px solid #d28d29;
            width: 45px;
            margin: auto;
        }
    }

    p {
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 30px;
        color: #f5f5f5;
    }

    form {
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid #191919;
        padding: 10px;
        border-radius: 5px;

        .hidden {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            padding: 0;
            color: #f5f5f5;
            clip: rect(0, 0, 0, 0);
        }

        input[type="text"], input[type="email"], textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #222831;
            border-radius: 5px;
            background-color: #191919;
            color: #f5f5f5;
        }

        input::placeholder, textarea::placeholder {
            color: #f5f5f5;
        }

        textarea {
            height: 150px;
        }

        button {
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            background-color: #191919;
            color: #f5f5f5;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #d28d29;
            }
        }
     }
}