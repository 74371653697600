@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  max-width: 1790px;
  margin: 0 auto;
  background-color: #000000;
  overflow-x: hidden;
}