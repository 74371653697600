//Home style

@keyframes headerAnim {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

#home {
    background: url('../../assets/background.webp') center/cover; 
    opacity: 0;
    animation: headerAnim 1.5s forwards;
}

.homeSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 0 1.5rem;
    gap: 5%;
    

    @media screen and (min-width: 984px) {
        display: flex;
        flex-direction: row;
        gap: 5%;
    }

    
    h1 {
        color: #f5f5f5;
        font-size: clamp(1.5rem, 5vw, 3rem);
        z-index: 1;
        animation: slide 1.5s forwards;
        @media screen and (max-width: 1160px){
            font-size: 2.5rem;
        }

        @media screen and (max-width: 984px){
            text-align: center;
            font-size: 2rem;
        }

        @media screen and (max-width: 430px){
            font-size: 1.5rem;
        }
    }

    span {
        color: #d39d38;
    }
 
    img {
        width: 35%;
        height: auto;
        object-fit: cover;
        border-radius: 50%;
        padding-top: 20px;

        @media screen and (max-width:984px){
            width: 40%;
        }

        @media screen and (max-width:768px){
            width: 50%;
            padding: 0;
        }
    }
  //style liens réseaux sociaux, avec animation au survol
    .socials {
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        display: flex;
        justify-content: center;
        padding: 1.5rem 1.5rem;
        max-width: 1440px;
        width: 100%;
        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
    
            li {
                margin: 0 1rem;
    
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    color: #000000;
                    background-color: #fff;
                    border-radius: 50%;
                    font-size: 1.5rem;
                    transition: transform 0.2s ease-in-out, color 0.2s, background-color 0.2s;
                }
    
                a:hover {
                    background-color: #d39d38;
                    color: #fff;
                    transform: translateY(-4px);
                }
    
            }
        }
    }
}