//Header style

.header {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1790px;
    transform: translateX(-50%);
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &__logo {
        display: flex;
        align-items: center;
    }

    &__nav {
        ul {
            display: flex;
            list-style: none;

            li {
                margin: 0 1rem;

                a {
                    color: #fff;
                    text-decoration: none;
                    font-weight: 700;
                    text-transform: uppercase;
                    transition: color 0.3s;

                    &:hover {
                        color: #d39d38;
                        cursor: pointer;
                    }

                    &.active {
                        color: #d39d38; 
                    }
                }
            }
        }
    }
&__menu-toggle {
    display: none;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 15%;
    right: 0;
    z-index: 1100;
  }

  @media screen and (max-width: 768px) {
    &__menu-toggle {
        display: block;
    }

    &__nav {
        position: fixed;
        top: 15%;
        right: 0;
        background: linear-gradient(to right,#000000, #434343);
        padding: 20px;
        width: 50%;
        
        transition: transform 0.3s ease;

        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;

        ul {
            flex-direction: column;

            li {
                margin: 5px 0;
                font-size: 14px;
                
               
            }
         }
        //slide du menu de navigation
        &.active {
            max-height: 200px;
            border-bottom: 2px solid #191919;
        }

        &.closed {
            position: absolute;
            top: -100%;
        }
    }
  }
} 